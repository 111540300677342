<template>
  <div ref="bindModal">
    <a-modal
        v-model:open="show"
        centered
        :get-container="() => $refs.bindModal"
        :footer="null"
        :closable="closable"
        :maskClosable="maskClosable"
        :keyboard="keyboard"
        width="820px"
        @cancel="handleClose"
    >
      <div class="bind">
        <div class="title">Connect Wallet</div>
        <div class="form">
          <div class="item">
            <div class="label">BSC Wallet Address</div>
            <input v-model="form.coin_address" :readonly="readonly" placeholder="Please insert address">
          </div>
          <div class="item">
            <div class="label">Safety PIN</div>
            <input
                v-model="form.pay_code"
                maxlength="6"
                type="password"
                placeholder="Safety PIN"
                @input="form.pay_code = form.pay_code.replace(/[^\d]/g,'')"
            >
          </div>
          <div class="item">
            <div class="label">Email Code</div>
            <div class="flex">
              <input v-model="form.verification_code" placeholder="Email Code">
              <a-button v-show="!isSend" :loading="loading" class="send" type="primary" @click="handleSend">Send Code</a-button>
              <div v-show="isSend" class="send btn">{{ second }}s</div>
            </div>
          </div>
        </div>
        <a-button :loading="loading1" class="confirmBtn" type="primary" @click="handleSubmit">Bind</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref, reactive, onBeforeUnmount } from 'vue'
  import { message } from "ant-design-vue";
  import { addUserCoin } from '@/api/wallet'
  import { getVerCode } from "@/api/user";

  const show = ref(false)
  const form = reactive({
    type: 1,
    coin_address: '',
    pay_code: '',
    verification_code: ''
  })
  defineExpose({ show, form })
  const emits = defineEmits(['closed', 'bound'])

  defineProps({
    closable: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    },
    keyboard: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  })

  const loading = ref(false)
  const loading1 = ref(false)

  function handleClose() {
    emits('closed')
    form.coin_address = ''
    form.pay_code = ''
    form.verification_code = ''
  }

  function handleSubmit() {
    if(form.coin_address === '') {
      message.warning('Please Enter the BSC Wallet Address')
      return
    }
    if(form.pay_code === '') {
      message.warning('Please Enter the Safety PIN')
      return
    }
    if(form.pay_code.length < 6) {
      message.warning('Please Enter 6 Digits')
      return
    }
    if(form.verification_code === '') {
      message.warning('Please Enter the Email Code')
      return
    }
    loading1.value = true
    addUserCoin(form).then(res => {
      if(res.success) {
        message.success('Successful operation')
        emits('bound')
        handleClose()
        clearInterval(timer)
        isSend.value = false
        second.value = 59
      } else {
        message.warning(res.msg)
      }
      loading1.value = false
    })
  }

  const isSend = ref(false)
  const second = ref(59)
  let timer = null

  function handleSend() {
    loading.value = true
    getVerCode().then(res => {
      if(res.success) {
        message.success('Sent successfully')
        isSend.value = true
        timer = setInterval(() => {
          if(second.value <= 1) {
            clearInterval(timer)
            isSend.value = false
            second.value = 59
            return
          }
          second.value--
        }, 1000)
      } else {
        message.warning(res.msg)
      }
      loading.value = false
    })
  }

  onBeforeUnmount(() => {
    clearInterval(timer)
  })
</script>

<style scoped lang="less">
:deep(.ant-modal){
  .ant-modal-content{
    border-radius: 15px;
    border: 1px solid #00C298;
    box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
    color: #606060;
    padding: 40px 0;
  }
}
.bind{
  font-size: 16px;
  padding: 0 40px;

  .title{
    font-size: 37px;
    line-height: 50px;
    font-weight: bold;
  }
  .form{
    .item{
      margin-top: 20px;

      .label{
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 10px;
      }
      input{
        height: 60px;
        box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
        border-radius: 15px;
        font-size: 21px;
        padding-left: 30px;
      }
      input::-webkit-input-placeholder {
        font-size: 21px;
      }
      .flex{
        display: flex;
        justify-content: space-between;

        input{
          width: 468px;
        }
        .send{
          width: 242px;
          height: 60px;
          background-color: #00C298;
          font-size: 28px;
          padding: 0;
          border-radius: 15px;
          box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
        }
        .btn{
          background: rgba(0, 194, 152, 0.5);
          cursor: default;
        }
      }
    }
  }
  .confirmBtn{
    margin: 40px auto 0 auto;
    width: 365px;
    height: 62px;
    border-radius: 15px;
    background: #00C298;
    font-size: 28px;
    font-weight: bold;
    display: block;
  }
}
</style>
