import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: '/',
    component: () => import('../views/Home/Index.vue')
  },
  {
    path: '/start',
    component: () => import('../views/Start.vue')
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/reset',
    component: () => import('../views/Reset.vue')
  },
  {
    path: '/success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/announce',
    component: () => import('../views/Home/Announcement.vue')
  },
  {
    path: '/annDetail',
    component: () => import('../views/Home/AnnDetail.vue')
  },
  {
    path: '/rwa',
    component: () => import('../views/Rwa/Index.vue')
  },
  {
    path: '/rwa/marketplace',
    component: () => import('../views/Rwa/Marketplace.vue')
  },
  {
    path: '/search',
    component: () => import('../views/Rwa/Search.vue')
  },
  {
    path: '/rwa/collection',
    component: () => import('../views/Rwa/Collection.vue')
  },
  {
    path: '/detail',
    component: () => import('../views/Rwa/Detail.vue')
  },
  {
    path: '/rwaDetail',
    component: () => import('../views/Rwa/RwaDetail.vue')
  },
  {
    path: '/oldDetail',
    component: () => import('../views/Rwa/OldDetail.vue')
  },
  {
    path: '/mall',
    component: () => import('../views/Mall/Index.vue')
  },
  {
    path: '/product',
    component: () => import('../views/Mall/Product.vue')
  },
  {
    path: '/proDetail',
    component: () => import('../views/Mall/Detail.vue')
  },
  {
    path: '/setting',
    component: () => import('../views/Setting/Index.vue')
  },
  {
    path: '/mine',
    component: () => import('../views/Mine/Index.vue')
  },
  {
    path: '/rank',
    component: () => import('../views/Mine/Rank.vue')
  },
  {
    path: '/reward',
    component: () => import('../views/Mine/Reward.vue')
  },
  {
    path: '/community',
    component: () => import('../views/Mine/Community.vue')
  },
  {
    path: '/order',
    component: () => import('../views/Mine/Order.vue')
  },
  {
    path: '/orderDetail',
    component: () => import('../views/Mine/OrderDetail.vue')
  },
  {
    path: '/game',
    component: () => import('../views/Game/Index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const whiteList = ['/login', '/register', '/start', '/reset', '/success']
router.beforeEach((to, from) => {
  if(whiteList.indexOf(to.path) === -1) {
    const token = localStorage.getItem('token')
    if(!token) {
      return '/start'
    }
  }
})

export default router;
