import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'

const app = createApp(App)
app.use(router)
app.component('Header', Header)
app.component('Menu', Menu)

import { Button, Carousel, Modal, Progress, Radio, Checkbox, Select, Divider,
  Drawer, Dropdown, Statistic, Upload, Popconfirm } from 'ant-design-vue'
app.use(Button).use(Carousel).use(Modal).use(Progress).use(Radio).use(Checkbox)
  .use(Select).use(Divider).use(Drawer).use(Dropdown).use(Statistic).use(Upload).use(Popconfirm)
app.mount('#app')
