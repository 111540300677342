<template>
  <div class="menu">
    <div :class="{ active: activeIndex === 0 }" class="item" @click="router.push('/')">
      <img class="icon" src="@/assets/img/icon_home.svg">
      <img class="icon" src="@/assets/img/icon_home_active.svg">
      <div class="text">Homepage</div>
    </div>
    <div :class="{ active: activeIndex === 1 }" class="item" @click="router.push('/rwa')">
      <img class="icon" src="@/assets/img/icon_rwa.svg">
      <img class="icon" src="@/assets/img/icon_rwa_active.svg">
      <div class="text">RWA Page</div>
    </div>
    <div :class="{ active: activeIndex === 2 }" class="item" @click="router.push('/mall')">
      <img class="icon" src="@/assets/img/icon_shop.svg">
      <img class="icon" src="@/assets/img/icon_shop_active.svg">
      <div class="text">MarketPlace</div>
    </div>
    <div :class="{ active: activeIndex === 3 }" class="item" @click="router.push('/game')">
      <img class="icon" src="@/assets/img/icon_sandbox.svg">
      <img class="icon" src="@/assets/img/icon_sandbox_active.svg">
      <div class="text">Sandbox<br/>Experience</div>
    </div>
    <div :class="{ active: activeIndex === 4 }" class="item" @click="router.push('/mine')">
      <img class="icon" src="@/assets/img/icon_mine.svg">
      <img class="icon" src="@/assets/img/icon_mine_active.svg">
      <div class="text">My Profile</div>
    </div>
    <div :class="{ active: activeIndex === 5 }" class="item" @click="router.push('/setting')">
      <img class="icon" src="@/assets/img/icon_setting.svg">
      <img class="icon" src="@/assets/img/icon_setting_active.svg">
      <div class="text">My Setting</div>
    </div>
  </div>
  <div class="block"></div>
</template>

<script setup>
  import { useRouter } from "vue-router";

  const router = useRouter()

  defineProps({
    activeIndex: {
      type: Number,
      default: -1
    }
  })
</script>

<style scoped lang="less">
.menu{
  width: 340px;
  background-color: #ffffff;
  padding-top: 20px;
  box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
  position: fixed;
  height: calc(100vh - 64px);

  .item{
    height: 78px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    margin-bottom: 24px;
    cursor: pointer;

    .icon{
      width: 40px;
      margin-right: 40px;
      display: none;
    }
    .icon:first-child{
      display: block;
    }
    .text{
      font-size: 28px;
      line-height: 28px;
    }
  }
  .active{
    background: #00C298;
    color: #ffffff;

    .icon{
      display: block;
    }
    .icon:first-child{
      display: none;
    }
  }
}
.block{
  width: 340px;
}
</style>
