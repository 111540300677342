<template>
  <div class="header">
    <div class="container">
      <a href="#/">
        <img class="logo" src="@/assets/img/logo.png">
      </a>
      <div v-if="showWallet && address" class="wallet">{{ getEllipsis(address, '...', 4, 4) }}</div>
      <div v-if="showWallet && !address" class="wallet" @click="init" style="cursor: pointer;">Connect Wallet</div>
    </div>
  </div>
  <div class="block"></div>

  <!--风险免责声明弹框-->
  <div ref="globalModal">
    <a-modal
        v-model:open="showState"
        centered
        class="stateModal"
        :get-container="() => $refs.globalModal"
        :closable="false"
        :footer="null"
        :maskClosable="false"
        :keyboard="false"
        width="820px"
    >
      <div class="statement">
        <div class="title">Risk Disclaimer</div>
        <div class="content">
          <p>Before you proceed with investing in Non-Fungible Tokens (NFTs) that represent real-world assets on My World, it's important to understand and consider the inherent risks. By continuing, you acknowledge and agree to the following:</p>
          <p><span>Market Volatility:</span> The value of NFTs can be highly volatile. Prices can fluctuate widely in a short period, potentially leading to significant losses.</p>
          <p><span>Liquidity Risk:</span> NFTs, especially those tied to real-world assets, may face liquidity issues. It might be difficult to sell or trade your NFTs quickly or without financial loss.</p>
          <p><span>Regulatory Uncertainty:</span> The legal framework around NFTs and tokenized assets is evolving. Future regulatory changes could impact the validity, ownership, and transferability of NFTs.</p>
          <p><span>Asset Valuation:</span> Valuations of real-world assets represented by NFTs are subject to change. These changes can affect the underlying value of your NFT investment.</p>
          <p><span>Technological Risks:</span> The blockchain technology underlying NFTs is subject to risks, including but not limited to, hacking, data loss, and operational failures that could lead to the loss of your investment.</p>
          <p><span>Legal and Compliance Risks:</span> Owning NFTs tied to real-world assets may involve complex legal and compliance obligations. It's essential to understand these before investing.</p>
          <p><span>Risk of Loss:</span> Investing in NFTs should be done with caution. There is a risk of losing your entire investment.</p>
          <p><span>Independent Advice:</span> We strongly recommend that you conduct your own due diligence and consider seeking advice from independent legal, financial, and tax advisors before making any investment.</p>
          <div class="bottom">By accessing and using this information, you acknowledge and agree to the terms of this disclaimer.</div>
          <div v-if="!(second < 1)" class="btn read">Agree ({{ second }}s)</div>
          <div v-else class="btn" @click="handleRead">Agree</div>
        </div>
      </div>
    </a-modal>
  </div>

  <!--链接已绑定的钱包地址-->
  <div ref="connectModal">
    <a-modal
        v-model:open="showConnect"
        centered
        :get-container="() => $refs.globalModal"
        :closable="false"
        :footer="null"
        :maskClosable="false"
        :keyboard="false"
        width="820px"
    >
      <div class="connect">
        <div class="title">Connect Wallet</div>
        <div class="text">Connect your linked wallet address to the Binance Smart Chain network.</div>
        <div class="label">Linked Wallet Address:</div>
        <div class="address">{{ bscAddr }}</div>
        <div class="btn" @click="showConnect = false">Return Back</div>
      </div>
    </a-modal>
  </div>

  <!--绑定钱包地址-->
  <Bind
      ref="bind"
      :closable="false"
      :mask-closable="false"
      :keyboard="false"
      :readonly="true"
  />
</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue'
  import { message } from "ant-design-vue";
  import { getUserCoin } from '@/api/wallet'
  import Bind from "@/views/Setting/Bind.vue";
  import { getEllipsis } from "@/utils";

  const Web3Modal = window.Web3Modal.default;
  const WalletConnectProvider = window.WalletConnectProvider.default;

  // Web3modal instance
  let web3Modal, network, provider, selectedAccount;
  let injectedWeb3 = null;
  const infura_key = 'c3e254116c559ae5e58746e69fb38787';
  let provider1,signer,gasPrice,usdtContract,conContract;

  const props = defineProps({
    showWallet: {
      type: Boolean,
      default: false
    }
  })

  const showState = ref(localStorage.getItem('read') !== '1' && props.showWallet)
  const second = ref(5)
  let timer = setInterval(() => {
    second.value--
    if(second.value < 1) {
      clearInterval(timer)
    }
  }, 1000)

  function handleRead() {
    init()
    showState.value = false
    localStorage.setItem('read', '1')
  }

  const form = reactive({
    coin_address: '',
    pay_code: '',
    verification_code: ''
  })

  const showConnect = ref(false)
  const bscAddr = ref('')
  const bind = ref(null)
  const address = ref('')

  onMounted(() => {
    if(props.showWallet && !showState.value) {
      init()
    }
  })

  const emits = defineEmits(['getContract'])

  async function init() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://uniswap.bridge.walletconnect.org",
          rpc: {
            56: 'https://bsc-dataseed.binance.org/',
            66: 'https://exchainrpc.okex.org',
            97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
            137: 'https://polygon-rpc.com/',
          },
          // Mikko's test key - don't copy as your mileage may vary
          infuraId: infura_key,
        }
      },
    };
    web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });
    try {
      provider = await web3Modal.connect();
      provider.enable()
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      alert("cannot connect wallet");
      return;
    }
    // Subscribe to accounts change
    provider.on("accountsChanged", async (accounts) => {
      await fetchAccountData();
    });
    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      await fetchAccountData();
    });
    // Subscribe to networkId change
    provider.on("networkChanged", async (networkId) => {
      await fetchAccountData();
    });
    provider.on("disconnect", async (code, reason) => {
      await onDisconnect();
    });
    await fetchAccountData(provider);
  }

  async function fetchAccountData() {
    const web3 = new Web3(provider);
    injectedWeb3 = web3;
    const accounts = await web3.eth.getAccounts();
    selectedAccount = accounts[0];
    network = await web3.eth.net.getId();
    if (network != 56) {
      message.warning('Please use Binance Smart Chain to connect')
      await onDisconnect();
    } else {
      const res = await getUserCoin()
      if(!res.data.length) {
        bind.value.show = true
        bind.value.form.coin_address = selectedAccount
      } else {
        const result = res.data.findIndex(item => {
          return item.type === 1
        })
        if(result < 0 || res.data[result].addr === '') {
          bind.value.show = true
          bind.value.form.coin_address = selectedAccount
        } else {
          bscAddr.value = res.data[result].addr
          if(bscAddr.value !== selectedAccount) {
            showConnect.value = true
            return
          }
        }
      }
      address.value = selectedAccount;
      gasPrice = await web3.eth.getGasPrice();
      provider1 = new ethers.providers.Web3Provider(web3.currentProvider);
      signer = provider1.getSigner();
      usdtContract = new ethers.Contract(contractConfig.usdtAddress, usdtAbi, signer);
      conContract = new ethers.Contract(contractConfig.conAddress, conAbi, signer);
      emits('getContract', {
        address: selectedAccount,
        usdtContract,
        conContract,
        gasPrice
      })
    }
  }

  async function onDisconnect() {
    console.log("Killing the wallet connection", provider);
    // TODO: Which providers have close method?
    if (address.value) {
      await web3Modal.clearCachedProvider();
      await provider.disconnect()
      provider = null;
      selectedAccount = null;
      address.value = '';
    }
  }
</script>

<style scoped lang="less">
.header{
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
  z-index: 999;

  .container{
    max-width: 1440px;
    height: 64px;
    margin: 0 auto;
    padding: 0 40px 0 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo{
      width: 48px;
    }
    .wallet{
      height: 40px;
      padding: 0 25px;
      display: flex;
      align-items: center;
      font-size: 21px;
      color: #ffffff;
      border-radius: 20px;
      background: #00C298;
      font-family: 'Poppins-Medium';
    }
  }
}
.block{
  height: 64px;
}
:deep(.ant-modal .ant-modal-content){
  border-radius: 15px;
  border: 1px solid #00C298;
  box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
  color: #606060;
  padding: 40px 0;
}
:deep(.stateModal .ant-modal-content){
  padding: 30px;
}
.statement{
  line-height: 1;

  .title{
    font-size: 28px;
    font-family: 'Poppins-Medium';
    margin-bottom: 20px;
  }
  .content{
    font-size: 16px;
    font-family: 'Poppins-Light';
    text-align: justify;

    p{
      margin-bottom: 15px;

      span{
        font-family: 'Poppins-Regular';
        font-weight: bold;
      }
    }
    .bottom{
      font-family: 'Poppins-Regular';
      font-weight: bold;
      text-align: center;
      margin-bottom: 36px;
    }
    .btn{
      width: 444px;
      height: 62px;
      font-size: 28px;
      margin: 0 auto;
      font-weight: bold;
      font-family: 'Poppins-Regular';
    }
    .read{
      background: #CDCDCD;
      cursor: default;
    }
  }
}
.connect{
  padding: 0 40px;
  font-size: 28px;
  color: #606060;

  .title{
    font-size: 37px;
    font-weight: bold;
    line-height: 50px;
    margin-bottom: 20px;
  }
  .text{
    line-height: 36px;
    margin-bottom: 20px;
  }
  .label{
    font-family: 'Poppins-Medium';
    margin-bottom: 10px;
  }
  .address{
    padding: 10px 0 10px 24px;
    border-radius: 15px;
    box-shadow: 0 1px 5px 0 rgba(0, 194, 152, 0.25);
  }
  .btn{
    width: 365px;
    height: 62px;
    border-radius: 15px;
    font-size: 28px;
    margin: 40px auto 0 auto;
    font-family: 'Poppins-Medium';
  }
}
</style>
